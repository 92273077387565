
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as hogabdckFzKbm5VTNMBt3gPtINJVYQHSVBjD2LTkxZMaRI0Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/hoga.vue?macro=true";
import { default as indexqwaihpp1uL_YM2w0yfUHgIv_458uqlJBfPynxuoWX3Hc0Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/index.vue?macro=true";
import { default as logoutGybzE1P8meLGogKB_457vFQAHV_45DSKdwbpq0bsEv_k06IMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/logout.vue?macro=true";
import { default as index7bZeZc5FthJ6qVKJsC5GnE4n962nobVwrSKooKAGNOQMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/account/index.vue?macro=true";
import { default as einstellungenfC0aHXw0zZyfu6fKYJYIAWfN8M_45nZu08R8FfT6itLgUMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/account/einstellungen.vue?macro=true";
import { default as account8YXHFxqCwT2_Q23b5rwsKPsS_SprPk7k2I3QFIKJxP8Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/account.vue?macro=true";
import { default as angebotPnxN0HfbNh2Ia5lw9_45vQsNf5CWu4vdaXH5PD27utQ2sMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/angebot.vue?macro=true";
import { default as kontaktUhb34uwf_45F1Jf6B_Rc03LlmpzUUp9b_dHWRyyXiNK88Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/kontakt.vue?macro=true";
import { default as abmeldend7IxY7XqPchX1ZbZl5CqHr0jgmAffr8GXjmFesDxZlcMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abmelden.vue?macro=true";
import { default as indexxyECniJjx9AHmOLKWFCpOWs78lYE4tVuZGFeQpbTRXoMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/index.vue?macro=true";
import { default as fertigiv7QsIO0kH_mCLi2VZ3I0o3K643EeO_ZsHrOtqypovgMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/fertig.vue?macro=true";
import { default as schritt_452bBPHjoW774SJy0HigSWp8TV_BmaoGuXS8LIAZOq8uzwMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-2.vue?macro=true";
import { default as schritt_453213zRU_45zC8E_H2n3WUZnQYaHIvIlhIESOkA1Hv0dDlEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-3.vue?macro=true";
import { default as schritt_454v9G9Skg4Hqh8OdVA7ddBVyYTY6YMOk0zf9U4aTFIn10Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-4.vue?macro=true";
import { default as schritt_4555QF8MOo1OrFy7B_456KQrBsjjsfKe5FZ_45UwyBuTXFZGYwMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-5.vue?macro=true";
import { default as schritt_456WB9Zxg8l3B_LLj_6UTegtkjw_45Lxqq5MmHWGzyG5JYr8Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-6.vue?macro=true";
import { default as schritt_457AHN3_45GlZWCg_toJONLRSUaYOZisZeN0DstI2fBDJSxYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-7.vue?macro=true";
import { default as schritt_458LDjK7jcCr6kf_451IUoZC_45ARN7Y5Nw_45eu598NuKPAbNQkMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-8.vue?macro=true";
import { default as fertigPMoJ1_GWzHSdku4vKFHcVUTVN9I1Dp_Fncxo_cbBsNkMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/fertig.vue?macro=true";
import { default as schritt_452Yu3_CuyDmhU_45Tlcr0Hk0wd_51GfrhCgsw_9JGB1SCcsMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-2.vue?macro=true";
import { default as schritt_453B7vUgOWSAxYlW5LD0_45x26gIdd5NkjtUNYX_naBdMJyIMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-3.vue?macro=true";
import { default as schritt_454qOb8CXF7u_u1bYGV_45GJ5Yq8wIipa4h4n0K1JlVZmY2oMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-4.vue?macro=true";
import { default as schritt_455xnG07JNWVfPIkyK8FrtL7z6BAwG5_r8Is3R0IBIflvEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-5.vue?macro=true";
import { default as schritt_4569w5GMa_EVf26vRurX1FIZfcTvpKDexonz_lXVVnBb94Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-6.vue?macro=true";
import { default as schritt_457Msv_LlgHMgJRU5Ve6Cvvxub8ZrqHw_acw8Ulw5WtK4QMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-7.vue?macro=true";
import { default as autohausNphzsm2eqiP9_w38VEfcehg3yihy0ueGZ2DKxr1jIP4Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus.vue?macro=true";
import { default as haendlerHazZ_45kOCaTLxRMWVwf_45HQroU7HC1A6_45SvD3WcM2u9rEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/haendler.vue?macro=true";
import { default as bewertungA3pHXDmF0AckS9VlF0RsIhY3uslQ3yW2aOybnQKrJB4Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/bewertung.vue?macro=true";
import { default as impressum51JwNhLrsE_8OONwFcZ4VTXqsJMp72ZQx_zv9Y2heJMMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/impressum.vue?macro=true";
import { default as ueber_45unsk0zlLI2i2ybQruTREs6qWgrlL29Q4h24nSQ80tmjoIsMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/ueber-uns.vue?macro=true";
import { default as indexNNT4N5Nbpd3eIZ5p3rCyAnNL5pZq69aptmOT_fHJnkEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/index.vue?macro=true";
import { default as dashboardY5oWlpLDbIAJk7FpL1r8_arSZEPEtIxvAK1VttkAl0gMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/dashboard.vue?macro=true";
import { default as indexmpgJaQU1winUM22p3G8v4igskNLaY0xRxvW_kChaWHYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/index.vue?macro=true";
import { default as berufevrlFvGBbMr0x_PcrsSissBXX3oIddmRYoeGvC3JdlegMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/berufe.vue?macro=true";
import { default as indexrOM7l01z6xmetMNA73e_xcYtDvGv18NhEIwWpVd0_3oMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/abrufscheine/index.vue?macro=true";
import { default as _91id_93ZB1L3OBBBk5laNtbHTMWZo0zIRSjUz_Gh9jXtt8K8pAMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/marken/[id].vue?macro=true";
import { default as indexK7VolnDaFRUssVRbZyJS_45kD5xzfgiUmRfZBTnTdx4WcMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/marken/index.vue?macro=true";
import { default as anfragenDMFUeF0CAcu9VCmrkUtI_DjwkqkC1DoAKRoMqbV0OMsMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/anfragen.vue?macro=true";
import { default as _91id_934rBI8vpCKE0MNJ6COzk5SDBMaJ0Ns57GGeQlEtv9GKIMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/haendler/abfrage/[id].vue?macro=true";
import { default as uebersichtbQpe0UuCaYZTJybRYTlHen4NOkTOkgYgSq0D8JlKwuUMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/uebersicht.vue?macro=true";
import { default as indexMkx_ueqkAzJaP8EXpKVWYnDgJAtUrKsvpmlheniydoYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/haendler/abfrage/index.vue?macro=true";
import { default as abmeldungenR9tcEWu_qeHC2a3stlbN0gtsLL9Sn1qvVLa6EUEPewQMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/abmeldungen.vue?macro=true";
import { default as _91id_937iiZCD4PFa_2aR_22cSWyNYiop3zH_45LFcx4NEE0PgjYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/profil/[id].vue?macro=true";
import { default as indexOF_45s0Mn8xQjNwhYKADB1wheCTy8mBDoY19TdCyeRn4cMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/haendler/insights/index.vue?macro=true";
import { default as indexTDIK9_45PuPv_45UpPYuR1lGvCA2G34BAZfma5Svef9yqfAMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/index.vue?macro=true";
import { default as _91id_93lMXC_BRcuUYntul1OrPmP3PArqSNstyCkM0Wzm6V4CoMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/informationen/[id].vue?macro=true";
import { default as indexSXVUh_xPL0pCDSONlpIAOnsHM32R60iMrKOx41j2iqIMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/insights/index.vue?macro=true";
import { default as _91id_93JdO0Dp7SamcJ8DLWgXtT5dnsZvWcgy91P3r6ygRtB2sMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/abrufscheine/anfragen/[id].vue?macro=true";
import { default as indexo_31flNYy_45Nr7_45U8eZjp4ooIeljGxxxvrLDCqDHcOrcMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/informationen/index.vue?macro=true";
import { default as indexB7tsoQyeSx3wXWmPHKLARjaj2mIhUDvTx7VNAI4Ocn4Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/abrufscheine/anfragen/index.vue?macro=true";
import { default as _91id_93WHdewuecTSutoVge0pV2axxoj9Bm6zp1EDGZ56JqwAMMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/nachrichten/[id].vue?macro=true";
import { default as indexqoWOqPvJL2DeVsBnJfLF0QgTYTKkVQMFZS5kvl8mMJUMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/nachrichten/index.vue?macro=true";
import { default as _91id_93RUPsc97k_7xvRdbm8atctpMkvBKWbdFkYDirHS7JxJYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/rechtliche-seiten/[id].vue?macro=true";
import { default as indexYPdd6Migwny3zII81dhPfuMtfR304Ysb6U5_MkKvMygMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/rechtliche-seiten/index.vue?macro=true";
import { default as indextUtaJQtlkIjcVYifHIRpLl0O4RYttsU8Y9Kp8pmVWUgMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/marken/index.vue?macro=true";
import { default as index9HukwPRklT8FTj25jjWmSsUf_CQ8h3SDmsa4jUyIipsMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/modelle/index.vue?macro=true";
import { default as _91id_93YmN_45oNi_45h3Vmh6_LccpTkUzcIYx6DjFChx3xbfr0nh4Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/fahrzeuge/[id].vue?macro=true";
import { default as _91id_93dWV5tHQyMVrs6H8RP56uDIIDJ3CNEIw3NSRmc1b7CP0Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/hauefig-gestellte-fragen/[id].vue?macro=true";
import { default as indexZN_45abMP_7vn1tW4QXvvALzARDkCnB3yCIqxaaQtVgA4Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/hauefig-gestellte-fragen/index.vue?macro=true";
import { default as neuXIUT6YmYOKv3jRgV5LhNJgKsa6sb9AZ6yvQId3dlojEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/nutzer-einwilligungs-einstellungen/neu.vue?macro=true";
import { default as _91id_93Y_a3PaFCXgARjBqGj0QskYb2iM2FHvEpWwGr4UCw7GYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/nutzer-einwilligungs-einstellungen/[id].vue?macro=true";
import { default as indextni1AFeiUBm5soD5B_Mx8j3_45NqcRyRIsIqdy_k_m3LYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/nutzer-einwilligungs-einstellungen/index.vue?macro=true";
import { default as backofficeFd966Ttxi27EldJASPkJ05u_WyvRMR8FsIwBQ0pJLakMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice.vue?macro=true";
import { default as indexqztXMMXw6gke_SYpOl21i_co_rPoN4pMGhvPu_45eXpfIMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/index.vue?macro=true";
import { default as fertigfptWK5k9lDKxazTOSZt2q3JCktu8zyHpYocPoToR0_45wMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/fertig.vue?macro=true";
import { default as schritt_452t08cttAOM22_45UbuASP_45uyIP226n0qd_45hWLIema8PYfQMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/schritt-2.vue?macro=true";
import { default as schritt_4531NmaSMTnplt7IluxiwnjlO58Z3ybl6BjrNNl90nMAFMMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/schritt-3.vue?macro=true";
import { default as schritt_454CpL_45fiG8cLwByDMVxseujrcyUFwKdIeH2NkUaexagzAMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/schritt-4.vue?macro=true";
import { default as abrufscheinWofQj9Md4uGe_2U5kSuWJPYi_Tes79YfWo188xynjSwMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein.vue?macro=true";
import { default as datenschutzk7UE2_45WzS89EVqsN0RsoZFIelAQ01nGv9GLFop5_45w3cMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/datenschutz.vue?macro=true";
import { default as emobilitaetJi51hqcF6HDvekwly9fUFH5IQHBoIRTiinUN0MANDtEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/emobilitaet.vue?macro=true";
import { default as kontakt_45altF7Q_Xt5scW37jlYE822Z0XERP2h5uX5KynzNz8LA5egMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/kontakt-alt.vue?macro=true";
import { default as indexZQsZROJFpr_r23OqVe9VE0ZnULWXt_5Qm1c4SQXJZzoMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/marken/index.vue?macro=true";
import { default as _91slug_939iYZAchUDuA3mb8M3HaQ3xkfEPOSre1CE3dT7rsaHe0Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/marken/[slug].vue?macro=true";
import { default as index_6z47EnGmO2KT2DEBlrRzQduQetYkY2fy2Y0SkugvcIMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/insights/index.vue?macro=true";
import { default as index3upRIPGc27R_bs107WPAq_45AQE4gWJeOQlOCq3JC8_45oYMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/exclusive/index.vue?macro=true";
import { default as _91slug_93m2wg7BTNFZAuCRdWfv_0Bm7o_45ndtc0CNaxuetDi_45OG4Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/insights/[slug].vue?macro=true";
import { default as indexe_y8arbZBBCdqAppesV4lfd1SUX8pdCZC5TX4U_c7BoMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/index.vue?macro=true";
import { default as fertigxiXPDpvwCbd9b6lDNx_45FCXLEcg6H8zvtRYkjMi_45ra1MMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/fertig.vue?macro=true";
import { default as schritt_452_sH20lutsrCvW9BZg4NCpE7C68C2SUZ0eFEHySUNXXsMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/schritt-2.vue?macro=true";
import { default as schritt_453YdaenL_45bD1ILfyiLz9NydCXr6IMcForlWKOzf8seHk0Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/schritt-3.vue?macro=true";
import { default as mitglied_45werdenwf3v9DAFjwt_45UpDAvJ3zNoi1U7_bcM14ovCk_45entgqQMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden.vue?macro=true";
import { default as indexvLkpELzWijbXYVIDgNQx_45UeZpUgYZ3cV9_jeLN_45NqEkMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/top-deals/index.vue?macro=true";
import { default as _91slug_93HhIp3MmqsITAiwCHncKWlpIqPBOnDk08FRrdU0CIZDEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/exclusive/[slug].vue?macro=true";
import { default as gruene_45top_45dealsD_EvT_45ozuU9gW6xTpv93S32CynRw0wkk3JOKQDnGzMkMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/gruene-top-deals.vue?macro=true";
import { default as _91slug_93_amqhmVmGaXXAxjOwz_45y5ciQHPK_jM_ZAq0JOstxjUgMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/top-deals/[slug].vue?macro=true";
import { default as nutzungsbedingungenpBweY0mJ6pm279NmhQ0poTDqWUMSHto5e9NKSlDJBlUMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/nutzungsbedingungen.vue?macro=true";
import { default as fragen_45und_45antwortensgirGyA5_45m55ZR_2EnQFErf8d5wLZ7lvQP1fV4HvmA8Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/fragen-und-antworten.vue?macro=true";
import { default as indexn8_t_BB4zl_45tYSm_7Gwsy9TqlzAaaJVZT5JovInhnQEMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/password/reset/index.vue?macro=true";
import { default as indexVzJSYYhA2HLkaDDk42jbRgBdOK0_45jSg7yhwSMMQp4eQMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/firmenwagen-finder/index.vue?macro=true";
import { default as social_45media_45datenschutzzjTQqDkVMzLO5mAMvkAMiCSiONH_A_458fZZDorkOA5tUMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/social-media-datenschutz.vue?macro=true";
import { default as explorercdlVdkQjm17p5_724s60J27Am_45AAotH_45a5927lWLEw0Meta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/firmenwagen-finder/explorer.vue?macro=true";
import { default as indexCgnASveN4HWS3SgtA9LfvtJ_45PplrrAL2lxnwRWvP5pwMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/gruener-firmenwagen-finder/index.vue?macro=true";
import { default as explorerMrIxAVjYXRSCEX5ii5MdXNFSOx8f8_1f33XF0CMumToMeta } from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/gruener-firmenwagen-finder/explorer.vue?macro=true";
export default [
  {
    name: "hoga",
    path: "/hoga",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/hoga.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutGybzE1P8meLGogKB_457vFQAHV_45DSKdwbpq0bsEv_k06IMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/logout.vue")
  },
  {
    name: account8YXHFxqCwT2_Q23b5rwsKPsS_SprPk7k2I3QFIKJxP8Meta?.name,
    path: "/account",
    meta: account8YXHFxqCwT2_Q23b5rwsKPsS_SprPk7k2I3QFIKJxP8Meta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/account.vue"),
    children: [
  {
    name: "account",
    path: "",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/account/index.vue")
  },
  {
    name: "account-einstellungen",
    path: "einstellungen",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/account/einstellungen.vue")
  }
]
  },
  {
    name: "angebot",
    path: "/angebot",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/angebot.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/kontakt.vue")
  },
  {
    name: "abmelden",
    path: "/abmelden",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abmelden.vue")
  },
  {
    name: autohausNphzsm2eqiP9_w38VEfcehg3yihy0ueGZ2DKxr1jIP4Meta?.name,
    path: "/autohaus",
    meta: autohausNphzsm2eqiP9_w38VEfcehg3yihy0ueGZ2DKxr1jIP4Meta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus.vue"),
    children: [
  {
    name: "autohaus",
    path: "",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/index.vue")
  },
  {
    name: "autohaus-neukunde-fertig",
    path: "neukunde/fertig",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/fertig.vue")
  },
  {
    name: "autohaus-neukunde-schritt-2",
    path: "neukunde/schritt-2",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-2.vue")
  },
  {
    name: "autohaus-neukunde-schritt-3",
    path: "neukunde/schritt-3",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-3.vue")
  },
  {
    name: "autohaus-neukunde-schritt-4",
    path: "neukunde/schritt-4",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-4.vue")
  },
  {
    name: "autohaus-neukunde-schritt-5",
    path: "neukunde/schritt-5",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-5.vue")
  },
  {
    name: "autohaus-neukunde-schritt-6",
    path: "neukunde/schritt-6",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-6.vue")
  },
  {
    name: "autohaus-neukunde-schritt-7",
    path: "neukunde/schritt-7",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-7.vue")
  },
  {
    name: "autohaus-neukunde-schritt-8",
    path: "neukunde/schritt-8",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/neukunde/schritt-8.vue")
  },
  {
    name: "autohaus-bestandskunde-fertig",
    path: "bestandskunde/fertig",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/fertig.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-2",
    path: "bestandskunde/schritt-2",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-2.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-3",
    path: "bestandskunde/schritt-3",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-3.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-4",
    path: "bestandskunde/schritt-4",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-4.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-5",
    path: "bestandskunde/schritt-5",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-5.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-6",
    path: "bestandskunde/schritt-6",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-6.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-7",
    path: "bestandskunde/schritt-7",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/autohaus/bestandskunde/schritt-7.vue")
  }
]
  },
  {
    name: "haendler",
    path: "/haendler",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/haendler.vue")
  },
  {
    name: "bewertung",
    path: "/bewertung",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/bewertung.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/impressum.vue")
  },
  {
    name: "ueber-uns",
    path: "/ueber-uns",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/ueber-uns.vue")
  },
  {
    name: backofficeFd966Ttxi27EldJASPkJ05u_WyvRMR8FsIwBQ0pJLakMeta?.name,
    path: "/backoffice",
    meta: backofficeFd966Ttxi27EldJASPkJ05u_WyvRMR8FsIwBQ0pJLakMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice.vue"),
    children: [
  {
    name: "backoffice",
    path: "",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/index.vue")
  },
  {
    name: "backoffice-dashboard",
    path: "dashboard",
    meta: dashboardY5oWlpLDbIAJk7FpL1r8_arSZEPEtIxvAK1VttkAl0gMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/dashboard.vue")
  },
  {
    name: "backoffice-mitglieder",
    path: "mitglieder",
    meta: indexmpgJaQU1winUM22p3G8v4igskNLaY0xRxvW_kChaWHYMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/index.vue")
  },
  {
    name: "backoffice-mitglieder-berufe",
    path: "mitglieder/berufe",
    meta: berufevrlFvGBbMr0x_PcrsSissBXX3oIddmRYoeGvC3JdlegMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/berufe.vue")
  },
  {
    name: "backoffice-abrufscheine",
    path: "abrufscheine",
    meta: indexrOM7l01z6xmetMNA73e_xcYtDvGv18NhEIwWpVd0_3oMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/abrufscheine/index.vue")
  },
  {
    name: "backoffice-intern-marken-id",
    path: "intern/marken/:id()",
    meta: _91id_93ZB1L3OBBBk5laNtbHTMWZo0zIRSjUz_Gh9jXtt8K8pAMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/marken/[id].vue")
  },
  {
    name: "backoffice-intern-marken",
    path: "intern/marken",
    meta: indexK7VolnDaFRUssVRbZyJS_45kD5xzfgiUmRfZBTnTdx4WcMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/marken/index.vue")
  },
  {
    name: "backoffice-mitglieder-anfragen",
    path: "mitglieder/anfragen",
    meta: anfragenDMFUeF0CAcu9VCmrkUtI_DjwkqkC1DoAKRoMqbV0OMsMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/anfragen.vue")
  },
  {
    name: "backoffice-haendler-abfrage-id",
    path: "haendler/abfrage/:id()",
    meta: _91id_934rBI8vpCKE0MNJ6COzk5SDBMaJ0Ns57GGeQlEtv9GKIMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/haendler/abfrage/[id].vue")
  },
  {
    name: "backoffice-mitglieder-uebersicht",
    path: "mitglieder/uebersicht",
    meta: uebersichtbQpe0UuCaYZTJybRYTlHen4NOkTOkgYgSq0D8JlKwuUMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/uebersicht.vue")
  },
  {
    name: "backoffice-haendler-abfrage",
    path: "haendler/abfrage",
    meta: indexMkx_ueqkAzJaP8EXpKVWYnDgJAtUrKsvpmlheniydoYMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/haendler/abfrage/index.vue")
  },
  {
    name: "backoffice-mitglieder-abmeldungen",
    path: "mitglieder/abmeldungen",
    meta: abmeldungenR9tcEWu_qeHC2a3stlbN0gtsLL9Sn1qvVLa6EUEPewQMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/abmeldungen.vue")
  },
  {
    name: "backoffice-mitglieder-profil-id",
    path: "mitglieder/profil/:id()",
    meta: _91id_937iiZCD4PFa_2aR_22cSWyNYiop3zH_45LFcx4NEE0PgjYMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/profil/[id].vue")
  },
  {
    name: "backoffice-haendler-insights",
    path: "haendler/insights",
    meta: indexOF_45s0Mn8xQjNwhYKADB1wheCTy8mBDoY19TdCyeRn4cMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/haendler/insights/index.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung",
    path: "fahrzeugverwaltung",
    meta: indexTDIK9_45PuPv_45UpPYuR1lGvCA2G34BAZfma5Svef9yqfAMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/index.vue")
  },
  {
    name: "backoffice-intern-informationen-id",
    path: "intern/informationen/:id()",
    meta: _91id_93lMXC_BRcuUYntul1OrPmP3PArqSNstyCkM0Wzm6V4CoMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/informationen/[id].vue")
  },
  {
    name: "backoffice-mitglieder-insights",
    path: "mitglieder/insights",
    meta: indexSXVUh_xPL0pCDSONlpIAOnsHM32R60iMrKOx41j2iqIMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/insights/index.vue")
  },
  {
    name: "backoffice-abrufscheine-anfragen-id",
    path: "abrufscheine/anfragen/:id()",
    meta: _91id_93JdO0Dp7SamcJ8DLWgXtT5dnsZvWcgy91P3r6ygRtB2sMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/abrufscheine/anfragen/[id].vue")
  },
  {
    name: "backoffice-intern-informationen",
    path: "intern/informationen",
    meta: indexo_31flNYy_45Nr7_45U8eZjp4ooIeljGxxxvrLDCqDHcOrcMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/informationen/index.vue")
  },
  {
    name: "backoffice-abrufscheine-anfragen",
    path: "abrufscheine/anfragen",
    meta: indexB7tsoQyeSx3wXWmPHKLARjaj2mIhUDvTx7VNAI4Ocn4Meta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/abrufscheine/anfragen/index.vue")
  },
  {
    name: "backoffice-mitglieder-nachrichten-id",
    path: "mitglieder/nachrichten/:id()",
    meta: _91id_93WHdewuecTSutoVge0pV2axxoj9Bm6zp1EDGZ56JqwAMMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/nachrichten/[id].vue")
  },
  {
    name: "backoffice-mitglieder-nachrichten",
    path: "mitglieder/nachrichten",
    meta: indexqoWOqPvJL2DeVsBnJfLF0QgTYTKkVQMFZS5kvl8mMJUMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/mitglieder/nachrichten/index.vue")
  },
  {
    name: "backoffice-intern-rechtliche-seiten-id",
    path: "intern/rechtliche-seiten/:id()",
    meta: _91id_93RUPsc97k_7xvRdbm8atctpMkvBKWbdFkYDirHS7JxJYMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/rechtliche-seiten/[id].vue")
  },
  {
    name: "backoffice-intern-rechtliche-seiten",
    path: "intern/rechtliche-seiten",
    meta: indexYPdd6Migwny3zII81dhPfuMtfR304Ysb6U5_MkKvMygMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/rechtliche-seiten/index.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-marken",
    path: "fahrzeugverwaltung/marken",
    meta: indextUtaJQtlkIjcVYifHIRpLl0O4RYttsU8Y9Kp8pmVWUgMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/marken/index.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-modelle",
    path: "fahrzeugverwaltung/modelle",
    meta: index9HukwPRklT8FTj25jjWmSsUf_CQ8h3SDmsa4jUyIipsMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/modelle/index.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-fahrzeuge-id",
    path: "fahrzeugverwaltung/fahrzeuge/:id()",
    meta: _91id_93YmN_45oNi_45h3Vmh6_LccpTkUzcIYx6DjFChx3xbfr0nh4Meta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/fahrzeugverwaltung/fahrzeuge/[id].vue")
  },
  {
    name: "backoffice-intern-hauefig-gestellte-fragen-id",
    path: "intern/hauefig-gestellte-fragen/:id()",
    meta: _91id_93dWV5tHQyMVrs6H8RP56uDIIDJ3CNEIw3NSRmc1b7CP0Meta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/hauefig-gestellte-fragen/[id].vue")
  },
  {
    name: "backoffice-intern-hauefig-gestellte-fragen",
    path: "intern/hauefig-gestellte-fragen",
    meta: indexZN_45abMP_7vn1tW4QXvvALzARDkCnB3yCIqxaaQtVgA4Meta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/hauefig-gestellte-fragen/index.vue")
  },
  {
    name: "backoffice-intern-nutzer-einwilligungs-einstellungen-neu",
    path: "intern/nutzer-einwilligungs-einstellungen/neu",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/nutzer-einwilligungs-einstellungen/neu.vue")
  },
  {
    name: "backoffice-intern-nutzer-einwilligungs-einstellungen-id",
    path: "intern/nutzer-einwilligungs-einstellungen/:id()",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/nutzer-einwilligungs-einstellungen/[id].vue")
  },
  {
    name: "backoffice-intern-nutzer-einwilligungs-einstellungen",
    path: "intern/nutzer-einwilligungs-einstellungen",
    meta: indextni1AFeiUBm5soD5B_Mx8j3_45NqcRyRIsIqdy_k_m3LYMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/backoffice/intern/nutzer-einwilligungs-einstellungen/index.vue")
  }
]
  },
  {
    name: abrufscheinWofQj9Md4uGe_2U5kSuWJPYi_Tes79YfWo188xynjSwMeta?.name,
    path: "/abrufschein",
    meta: abrufscheinWofQj9Md4uGe_2U5kSuWJPYi_Tes79YfWo188xynjSwMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein.vue"),
    children: [
  {
    name: "abrufschein",
    path: "",
    meta: indexqztXMMXw6gke_SYpOl21i_co_rPoN4pMGhvPu_45eXpfIMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/index.vue")
  },
  {
    name: "abrufschein-fertig",
    path: "fertig",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/fertig.vue")
  },
  {
    name: "abrufschein-schritt-2",
    path: "schritt-2",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/schritt-2.vue")
  },
  {
    name: "abrufschein-schritt-3",
    path: "schritt-3",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/schritt-3.vue")
  },
  {
    name: "abrufschein-schritt-4",
    path: "schritt-4",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/abrufschein/schritt-4.vue")
  }
]
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/datenschutz.vue")
  },
  {
    name: "emobilitaet",
    path: "/emobilitaet",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/emobilitaet.vue")
  },
  {
    name: "kontakt-alt",
    path: "/kontakt-alt",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/kontakt-alt.vue")
  },
  {
    name: "marken",
    path: "/marken",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/marken/index.vue")
  },
  {
    name: "marken-slug",
    path: "/marken/:slug()",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/marken/[slug].vue")
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/insights/index.vue")
  },
  {
    name: "exclusive",
    path: "/exclusive",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/exclusive/index.vue")
  },
  {
    name: "insights-slug",
    path: "/insights/:slug()",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/insights/[slug].vue")
  },
  {
    name: mitglied_45werdenwf3v9DAFjwt_45UpDAvJ3zNoi1U7_bcM14ovCk_45entgqQMeta?.name,
    path: "/mitglied-werden",
    meta: mitglied_45werdenwf3v9DAFjwt_45UpDAvJ3zNoi1U7_bcM14ovCk_45entgqQMeta || {},
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden.vue"),
    children: [
  {
    name: "mitglied-werden",
    path: "",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/index.vue")
  },
  {
    name: "mitglied-werden-fertig",
    path: "fertig",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/fertig.vue")
  },
  {
    name: "mitglied-werden-schritt-2",
    path: "schritt-2",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/schritt-2.vue")
  },
  {
    name: "mitglied-werden-schritt-3",
    path: "schritt-3",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/mitglied-werden/schritt-3.vue")
  }
]
  },
  {
    name: "top-deals",
    path: "/top-deals",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/top-deals/index.vue")
  },
  {
    name: "exclusive-slug",
    path: "/exclusive/:slug()",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/exclusive/[slug].vue")
  },
  {
    name: "gruene-top-deals",
    path: "/gruene-top-deals",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/gruene-top-deals.vue")
  },
  {
    name: "top-deals-slug",
    path: "/top-deals/:slug()",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/top-deals/[slug].vue")
  },
  {
    name: "nutzungsbedingungen",
    path: "/nutzungsbedingungen",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/nutzungsbedingungen.vue")
  },
  {
    name: "fragen-und-antworten",
    path: "/fragen-und-antworten",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/fragen-und-antworten.vue")
  },
  {
    name: "password-reset",
    path: "/password/reset",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/password/reset/index.vue")
  },
  {
    name: "firmenwagen-finder",
    path: "/firmenwagen-finder",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/firmenwagen-finder/index.vue")
  },
  {
    name: "social-media-datenschutz",
    path: "/social-media-datenschutz",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/social-media-datenschutz.vue")
  },
  {
    name: "firmenwagen-finder-explorer",
    path: "/firmenwagen-finder/explorer",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/firmenwagen-finder/explorer.vue")
  },
  {
    name: "gruener-firmenwagen-finder",
    path: "/gruener-firmenwagen-finder",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/gruener-firmenwagen-finder/index.vue")
  },
  {
    name: "gruener-firmenwagen-finder-explorer",
    path: "/gruener-firmenwagen-finder/explorer",
    component: () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/pages/gruener-firmenwagen-finder/explorer.vue")
  }
]